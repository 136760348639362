.popular-prod-intro.text-content {
  background-color: $grey-light;
  text-align: center;
  padding: 60px 0 40px !important;
  margin: 0;

  @media (min-width: 1024px) {
    padding: 60px 0 0 !important;
  }

  .container {
    position: relative;

    .popular-title-section {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .subtitle {
        font-weight: 500px;
      }

      .text {
        max-width: 430px;
      }
    }

    .popular-link {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        @include newsletter-link;
      }
    }
  }
}

.home-page {
  .gtm-product-list {
    background-color: $grey-light;
    padding-bottom: 40px !important;
  }
}

// Shop Slider
.product-list {
  &.home-shop-slider {
    @include container;
    text-align: center;
  }

  &__item {
    margin-bottom: 0;
    height: auto !important;
    background-color: transparent;
    color: $dark-grey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    flex: 0 0 98% !important;

    @media (min-width: 400px) {
      flex: 0 0 48% !important;
    }

    @media (min-width: 900px) {
      flex: 0 0 31.333% !important;
    }

    @media (min-width: 1024px) {
      flex: 0 0 25% !important;
    }

    .product-box {
      height: 100%;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding-bottom: 23px;
      gap: 12px;

      &__header {
        padding: 0;
      }

      &__title {
        font-family: "Crimson Pro", serif;
        font-weight: 400;
        font-size: 18px;
        color: $dark-brown-grey;
        line-height: 25px;
        margin: 0;
        padding-bottom: 12px;
        max-height: unset;
        min-height: unset;
      }

      &__image {
        img {
          padding: 0;
          padding-bottom: 34px;
          aspect-ratio: auto;
          min-width: 100%;
        }
      }

      &__body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 84px;
      }

      &__price {
        @include text;
        font-weight: 700;
        margin-bottom: 24px;
      }

      &__action {
        margin: 0;
        display: flex;
        justify-content: center;

        .button--primary {
          @include btn;
          font-size: 14px;
          line-height: 21px;
          width: 187px;
          height: 41px;
          letter-spacing: 0;
        }
      }

      &__notavailable {
        @include text;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
}
