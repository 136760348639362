// Home Card Section
.text-content.home-card-section {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $white;

  @media (min-width: $md) {
    min-height: 100%;
    max-height: 100%;
  }

  .text-content__title {
    display: none;
  }

  .text-content__text {
    .container-fluid {
      background-color: $white;

      .col {
        &-img {
          display: flex;
          justify-content: flex-start;
          padding: 0;

          .card-img {
            width: 100%;
            height: auto;
            min-height: 340px;
            object-fit: cover;
            object-position: center !important;
            aspect-ratio: 1.5;

            @media (min-width: $md) {
              max-height: 100%;
              max-width: 100%;
              width: auto;
              height: 100%;
              object-position: right center;
            }

            @media (min-width: $lg) {
              max-height: 546px;
              max-width: 610px;
            }
          }
        }

        &-txt {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 40px 20px;

          @media (min-width: $md) {
            align-items: flex-start;
            padding: 40px 60px;
          }

          .text {
            max-width: 650px;
          }

          .subtitle {
            display: block;
            text-align: center;
            font-size: 20px;
            margin-bottom: 10px;
            color: $dark-grey;
            max-width: 650px;

            @media (min-width: $micro) {
              font-size: 30px;
              margin-bottom: 20px;
            }

            @media (min-width: $md) {
              text-align: left;
            }
          }

          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0 auto;

            @media (min-width: $md) {
              max-width: unset;
              margin: unset;
            }
          }
        }
      }
    }

  }

  &:nth-of-type(odd) {
    .container-fluid {
      @media (min-width: $md) {
        background-color: $pink;
      }
      .col {
        &-img {
          @media (min-width: $md) {
            order: 1;
            justify-content: flex-end;
          }
        }

        &-txt {
          @media (min-width: $md) {
            order: 0;
            align-items: flex-end;
          }
        }
      }
    }
  }
}
