.concept-image-slider {
  height: 44.27vw;
  max-height: 620px;
  margin: 0;

  .slideshow__title {
    display: none;
  }
  .swiper.swiper-wrapper {
    height: 44.27vw;
    max-height: 620px;

    .swiper-wrapper {
      .swiper-slide {
        height: 44.27vw;
        max-height: 620px;

        .slideshow-slide {
          .slideshow-slide__title {
            display: none;
          }
          .slideshow-slide__image {
            img {
              object-fit: cover;
              aspect-ratio: 0.375;
              max-height: 620px;
            }
          }
        }
        .slideshow-slide__text {
          display: none;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-pagination {
      bottom: 20px;

      @media (min-width: $md) {
        bottom: 66px;
      }

      .container {
        display: flex;
        justify-content: center;
        align-items: center;

        .swiper-pagination-bullet {
          width: 20px;
          height: 2px;
          border: none;
          border-radius: 0;
          background-color: $white;
          opacity: 0.5;
          transition: 0.3s;

          &-active {
            opacity: 1;
          }
        }
      }
    }
  }
}
