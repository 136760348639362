body {
  &:not(.home-page) {
    main {
      &.wrap {
        background-color: $grey-light;

        .home-hero {
          &__body {
            &__inner {
              @media (min-width: $lg) {
                min-height: 700px !important;
                height: 700px !important;
                max-height: 700px;
                aspect-ratio: 1.5;
              }
            }
          }

          &__image {
            img {
              @media (min-width: $lg) {
                object-position: top center;
              }
            }
          }

          &__heading {
            h1 {
              font-family: "Crimson Pro", serif;
              font-size: 50px;
              font-weight: 400;
              line-height: 56px;
              text-transform: unset;
              margin-bottom: 20px;
            }
          }

          &__text {
            font-family: "Karla", sans-serif;
            font-size: 20px;
            line-height: 24px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .title_block {
          font-family: "Karla", sans-serif;
          font-size: 24px;
          line-height: 24px;
          text-transform: uppercase;
          color: $dark-grey;
          font-weight: 500;
          margin-top: 44px;
          margin-bottom: 54px;
        }

        .category {
          &-intro {
            margin-top: 0;
            margin-bottom: 0;

            &__description {
              margin: 0 auto;

              @include container;
              text-align: center !important;
              margin-top: 54px;

              .text_block {
                span {
                  font-family: "Karla", sans-serif;
                  display: block;
                  width: 100%;
                  max-width: 510px;
                  margin: 0 auto;
                  color: $grey;
                }

                &:first-of-type {
                  .page-title,
                  .title_block {
                    font-family: "Crimson Pro", serif;
                    letter-spacing: 0;
                    font-size: 40px;
                    line-height: 45px;
                    text-transform: unset;
                    font-weight: 400;
                    margin-top: 0;
                    margin-bottom: 34px;
                  }
                }
              }
            }
          }
        }

        .gtm-product-list {
          .product-list {
            @include container;
            padding-top: 0;
            padding-bottom: 0;

            &__item {
              min-height: 100%;
              flex: 0 0 98% !important;

              @media (min-width: 400px) {
                flex: 0 0 48% !important;
              }

              @media (min-width: 900px) {
                flex: 0 0 31.333% !important;
              }

              @media (min-width: 1024px) {
                flex: 0 0 25% !important;
              }

              .product-box {
                height: 100%;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                padding-bottom: 43px;
                gap: 12px;

                &__header {
                  padding: 0;
                }

                &__title {
                  font-family: "Crimson Pro", serif;
                  font-weight: 400;
                  font-size: 18px;
                  color: $dark-brown-grey;
                  line-height: 25px;
                  margin: 0;
                  padding-bottom: 12px;
                  max-height: unset;
                  min-height: unset;
                }

                &__image {
                  img {
                    padding: 0;
                    margin-bottom: 44px;
                  }
                }

                &__body {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 84px;
                }

                &__price {
                  @include text;
                  font-weight: 700;
                  margin-bottom: 24px;
                }

                &__notavailable {
                  @include text;
                  font-weight: 700;
                  margin-bottom: 0;
                }

                &__action {
                  margin: 0;
                  display: flex;
                  justify-content: center;

                  .button--primary {
                    @include btn;
                    height: 41px;
                    letter-spacing: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.category {
  &-montalcino {
    main.wrap .home-hero__image img {
      object-position: center top !important;

      @media (min-width: $giga) {
        object-position: center -13vw !important;
      }
    }
  }

  &-toscana {
    main.wrap .home-hero__image img {
      object-position: left center;
    }
  }

  &-gift-box {
    main.wrap .home-hero {
      &__body__inner {
        @include container;
      }

      &__heading h1,
      &__text {
        text-align: left;
      }

      &__text {
        max-width: 80rem;
      }
    }
  }
}
