// Section Newsletter
.newsletter-section.text-content {
  background-color: $grey-light;
  padding: 80px 0 !important;

  .text-content__title {
    display: none;
  }

  .text-content__text {
    .container {
      .row {
        .col-12 {
          .intro {
            color: $gold;
            margin-bottom: 16px;
          }

          &.col-md-5 {
            .subtitle {
              color: $dark-grey;
              margin-bottom: 26px;
              @media (min-width: $md) {
                max-width: 450px;
              }
            }

            p {
              margin: 0;
              @media (min-width: $md) {
                max-width: 450px;
              }
            }
          }

          &.col-md-7 {
            .form-newsletter {
              color: $dark-grey;
              margin-top: 40px;

              #nome,
              #cognome,
              #email {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid $dark-grey;
                padding-bottom: 0.93rem;
                margin-bottom: 0.93rem;

                &::placeholder {
                  text-transform: uppercase;
                }
              }

              .row {
                margin-bottom: 26px;

                .col-12 {
                  .input-wrapper {
                    margin-top: 1.25rem;
                    position: relative;
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    justify-content: flex-start;

                    &.checked {
                      &::after {
                        background-color: $blue;
                      }
                    }

                    &::after {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 20px;
                      height: 20px;
                      border: 1px solid $dark-grey;
                      background-color: $grey-light;
                      transition: 0.3s;
                    }

                    #privacy {
                      position: relative;
                      z-index: 100;
                      top: 0;
                      left: 0;
                      margin: 0;
                      opacity: 0;
                      width: 20px;
                      height: 20px;
                    }

                    .privacy-label {
                      margin: 0;
                      line-height: 16px;
                    }
                  }

                  &.col-lg-auto {
                    align-self: end;
                    @media (min-width: $lg) {
                      margin-bottom: 2.75rem;
                    }

                    #subscribe {
                      border-color: $gold;
                      width: 110px;
                      height: 40px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 14px;
                      line-height: 17px;
                      letter-spacing: 2px;
                      transition: 0.3s;

                      @media (min-width: $lg) {
                        margin-top: 1.625rem;
                      }

                      &:disabled {
                        opacity: 0.5;
                      }

                      &:hover:not(:disabled) {
                        color: $white;
                        background-color: $gold;
                      }
                    }

                    @media (min-width: $lg) {
                      #subscribe {
                        margin-top: 0;
                      }
                    }
                  }
                }
              }

              .newsletter-link {
                display: block;
                letter-spacing: 2.1px;
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }
  }
}
