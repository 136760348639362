* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Crimson Pro", serif;
  color: $dark-grey;
  opacity: 1;

  .text-content {
    padding: 0 !important;

    &:has(#shipping-extra) {
      padding: 0 !important;
    }

    &__title {
      margin-bottom: 0 !important;
    }
  }

  &.generic-page {
    .text-content {
      &__title {
        margin-bottom: 20px !important;
      }
    }
  }

  &:not(.generic-page) {
    .text-content {
      &__title {
        margin-bottom: 0 !important;
      }
    }
  }
}

// Main Content
main {
  &.wrap {
    display: flex;
    flex-direction: column;
    flex: auto;
    padding-top: 81px;

    @media (min-width: $md) {
      padding-top: 140px;
    }

    .wrap {
      .single-slide {
        position: relative;
        width: 100%;
      }

      .img-box {
        position: absolute;
        width: 100%;

        .col-12 {
          padding: 0;
        }
      }

      .text-box {
        // position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: $md) {
          position: absolute;
        }

        .text-col {
          padding-right: 60px;
        }
      }
    }
  }
}

a {
  color: inherit;
  text-decoration: none !important;
}

// Font
.intro {
  @include intro;
}

.title {
  @include title;
}

.subtitle {
  @include subtitle;
}

.text {
  @include text;
}

.btn {
  @include btn;
}

.newsletter-link {
  @include newsletter-link;
}

.single-prod-title {
  @include single-prod-title;
}

.container,
.container-xxl {
  @media (min-width: $giga) {
    max-width: 1800px !important;
  }

  @media (min-width: $xgiga) {
    max-width: 3000px !important;
  }
}

// Prod Box
.product-box__body {
  .product-box__price {
    span {
      font-weight: 700;
      color: $dark-grey;
    }
  }

  .product-box__action {
    .button--primary {
      @include btn;
      width: auto;
      font-size: 0.6rem;
      text-align: center;
      border: 1px solid $gold;
      background-color: $white;
      color: $dark-grey;

      &:hover {
        background-color: $gold;
        color: $white;
      }
    }
  }
}

#email {
  border: 0;
  border-bottom: 1px solid #989898;
  background-color: transparent;
}

#subscribe {
  width: auto;
  font-size: 0.6rem;
  text-align: center;
  border: 1px solid #bd9b60;
  background-color: transparent;
  color: #53565a;
}

.generic-page {
  main.wrap {
    background-color: $grey-light;

    .page-hero {
      display: none;
    }
    .wrap {
      @include container;

      .text-content {
        padding-top: 30px !important;
        padding-bottom: 30px !important;

        & > * {
          @include text;
          background-color: transparent;
        }

        .text-content__title {
          @include subtitle;
        }
      }
    }
  }
}

.button--primary {
  @include btn;
  margin: 0 auto;
}
