// Single Product Page
.product-page {
  background-color: $grey-light;

  main.wrap {
    background-color: $grey-light !important;

    .product-hero {
      @include container;
      padding-top: 122px !important;
      padding-bottom: 40px;
      justify-content: space-between;

      @media (max-width: 600px) {
        padding-top: 7rem !important;
        padding-bottom: 1rem;
      }

      .product-hero__image {
        width: 100%;
        padding: 0;

        .product-hero__image__gallery {
          margin: 0;

          @media (max-width: 600px) {
            padding-bottom: 3rem;
          }

          .product-hero__image__thumbs {
            margin-top: 0;
            width: 100%;
          }

          .swiper {
            &-initlialized {
              width: 100%;
            }

            &-button-prev,
            &-button-next {
              display: none;
            }

            &-slide {
              &:nth-of-type(1) {
                margin-left: 0;
              }
            }
          }
        }
      }

      .product-hero__content {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
          padding-left: 33px;
        }

        .product-hero__title {
          @include title;
          color: $brown-grey;
          order: 0;
          font-size: 1.7rem;
          line-height: 1.8rem;
          font-weight: 300;
          margin-bottom: 0.875rem;
        }

        .product-hero__description {
          order: 1;
          .single_subtitle,
          .single_year {
            display: none;
          }
        }

        .product-hero__content__wrap {
          order: 2;
          margin-top: 14px;

          .product-hero__price {
            span {
              @include subtitle;
              font-family: "Karla", sans-serif;
              font-weight: 600;
              margin-bottom: 40px;
              span {
                margin-bottom: 0;
              }
            }
          }

          .product-hero__component {
            margin-top: 0;
          }

          .product-hero__quantity-selector {
            border: 1px solid $dark-grey;
            border-radius: 0;

            .button,
            span {
              font-weight: 700;
            }
          }

          .product-hero__action {
            .button--primary {
              height: 49px;
              background-color: $brown-grey;
              border: 1px solid $brown-grey;
              display: flex;
              align-items: center;
              opacity: 1;
              transition: 0.3s;

              &:hover {
                opacity: 0.7;
              }
            }
          }
        }

        .product-hero__info-list {
          order: 3;
          margin-top: 2.625rem;

          .product-hero__info-item {
            .product-info__content {
              .product-info__title {
                @include single-prod-title;
                color: $gold;
              }
            }
          }
        }
      }
    }

    .product-detail {
      background-color: $grey-light;
      margin-left: 0vw;
      margin-right: 0vw;
      padding: 0;
      margin-bottom: 0;
      min-width: 100vw;
      max-width: 100vw;

      .product-detail__item {
        display: none;
        padding-left: 12vw;
        padding-right: 12vw;

        &.banner,
        &.video {
          display: block !important;
          padding: 0;
          margin: 0;

          .product-detail__title {
            display: none;
          }
        }

        &.ipremi {
          display: block;
          background-color: $white;
          padding: 80px 0;
          position: relative !important;
          margin-bottom: 0 !important;

          .product-detail__title {
            @include container;
            @include subtitle;
            text-transform: capitalize;
            color: $dark-grey;

            @media (min-width: $md) {
              padding-left: 123px;
            }
          }

          .product-detail__content {
            @include container;

            @media (min-width: $md) {
              padding-left: 123px;
            }

            ul {
              li {
                @include intro;
                font-weight: 700;
                display: flex;
                margin-bottom: 28px;
                text-transform: unset;
                display: flex;
                justify-content: space-between;

                @media (min-width: $md) {
                  display: list-item;
                }

                strong {
                  font-family: "Karla", sans-serif;
                  line-height: 19px;
                  margin-bottom: 0;
                  color: $red;
                  text-transform: uppercase;

                  @media (min-width: $md) {
                    width: 290px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .product-detail__item {
    &.confezione,
    &.box,
    &.packaging {
      .product-detail__title {
        display: none;
      }
      .product-detail__content {
        display: flex;
        flex-direction: column;

        p {
          font-weight: 600;
          color: $black;
        }
        img {
          width: 100%;
          height: 100%;
          max-width: 200px;
          object-fit: contain;
        }
      }
    }

    &.brochure,
    &.schedatecnica,
    &.datasheet,
    &.technicalsheet {
      display: inline-block;
      width: auto;
      text-align: left;
      margin-top: 96px;

      .product-detail__title {
        display: none;
      }

      .product-detail__content {
        .schedatecnica_link {
          display: flex;
          align-items: center;
          color: $dark-grey;
          font-family: "Karla", sans-serif;
          font-size: 12px;
          line-height: 19px;
          letter-spacing: 2.4px;
          text-transform: uppercase;
          position: relative;
          overflow: hidden;
          max-width: fit-content;
          justify-content: center;
          opacity: 1;
          @include transition_custom;
          img {
            max-height: 22px;
            margin-right: 0.7rem;
          }
          span {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            &:before {
              content: "";
              width: 100%;
              display: block;
              height: 1px;
              background-color: $dark-grey;
              bottom: 0;
              position: absolute;
              @include transition_custom;
            }
          }

          &:hover {
            opacity: 0.7;
            span {
              &:before {
                width: 0;
              }
            }
          }
        }
      }
    }

    &.brochure {
      margin-left: 60px !important;
    }
  }
}


.html5-video-player.ytp-impression-link {
  display: none !important;
}