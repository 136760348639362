.category-menu {
  background-color: $pink-dark;
  color: $white;
  height: 100%;
  padding: 0;

  &__list {
    @include container;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (min-width: $md) {
      padding-top: 0;
      padding-bottom: 0;
    }

    @media (min-width: $xl) {
      justify-content: space-evenly;
      flex-wrap: nowrap;
    }
  }

  &__item {
    padding: 0;
    margin: 0;
    min-width: unset;
    width: 100%;
    max-width: 50%;

    @media (min-width: $md) {
      max-width: 33.33%;
    }

    @media (min-width: $xl) {
      width: auto;
      max-width: unset;
    }

    a {
      display: block;
      font-family: "Karla", sans-serif;
      font-size: 17px;
      line-height: 20px;
      font-weight: 700;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: opacity 0.3s ease-in-out;

      @media (min-width: $sm) {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      @media (min-width: $xl) {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      &:hover,
      &:active {
        color: #fff !important;
        opacity: 0.5;
      }
    }
  }
}
