.footer {
  padding: 0;
  font-family: "Karla", sans-serif;

  &__main {
    text-align: center !important;

    @media (min-width: $sm) {
      text-align: left !important;
    }
  }

  &__logo {
    display: none !important;
  }

  &__blocks {
    margin: 0;

    &__item {
      margin: 0;

      &__title {
        display: none;
      }
    }
  }

  &-custom {
    background-color: #041e42;
    color: #fff;
    padding: 48px 0;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;
      color: #fff;
      transition: 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    .contact-row {
      margin-bottom: 30px;

      .logo {
        &-col {
          display: flex;
          flex-direction: column;
          align-items: center;

          h2 {
            @include subtitle;
            color: #fff;
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 30px;
            font-style: italic;

            @media (min-width: $md) {
              margin-bottom: 0;
              font-size: 20px;
              line-height: 23px;
            }
          }
        }

        &-footer {
          width: 66px;
          margin-bottom: 1rem;
        }
      }

      .social-col {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo-cert {
          width: 55px;
          margin-bottom: 16px;
        }
      }

      .col {
        &-title {
          @include intro;
          color: #fff;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 2.4px;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        &-list {
          &.social {
            display: flex;
            align-items: center;
            gap: 7px;
            margin-bottom: 22px;
          }

          &-item {
            display: flex;
            gap: 7px;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            line-height: 22px;

            @media (min-width: $sm) {
              justify-content: flex-start;
            }

            .col-list-icon {
              width: 12px;
              height: 12px;
              object-fit: contain;
              display: block;
            }
          }
        }
      }
    }

    .credits-row {
      justify-content: center;

      @media (min-width: $md) {
        justify-content: flex-end;
      }
    }
  }

  &-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.social-icon {
  width: 19px !important;
  height: 19px !important;
  object-fit: contain;
}
