// Header Navigator
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: $white;
  color: $dark-grey;
  padding: 0;
  max-width: 100% !important;
  margin: 0 !important;
  height: auto;
  max-height: 90px;
  transition: 0.3s;

  @media (min-width: $md) {
    max-height: 140px;
    padding: 20px 0;
  }

  .wrap {
    @include container;

    .nav {
      align-items: center;

      .nav__menu {
        height: 50px;
        justify-content: start !important;

        .site-nav {
          flex-direction: row;
          align-items: center;

          .crest {
            object-fit: contain;
            width: 40px;
            height: 50px;
            margin-right: 20px;
            transition: 0.3s;

            @media (min-width: $md) {
              width: 80px;
              height: 100px;
              margin-right: 30px;
            }

            &.scrolled {
              @media (min-width: $md) {
                width: 40px;
                height: 50px;
                margin-right: 20px;
              }
            }
          }

          .site-nav__toggle {
            height: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .site-nav__toggle__input__item {
              width: 25px;
              height: 1px;
              background-color: $dark-grey;
              margin: 0;
            }
          }

          .site-nav__menu {
            font-family: "Karla", sans-serif;
            background-color: $grey-light;
            padding: 100px 0;

            &.is-open {
              width: 100%;

              @media (min-width: $sm) {
                width: 325px;
              }

              .locale-switcher {
                display: flex;

                &__link {
                  display: flex;
                  align-items: center;
                }
              }
            }

            .site-nav__toggle__label {
              .site-nav__toggle__label__item {
                background-color: $dark-grey !important;
              }
            }

            .site-nav__menu__list {
              @include container;

              .site-nav__menu__item {
                padding-left: 1rem;

                .site-nav__menu__link {
                  color: $black;
                  font-weight: 300;
                }
              }
            }

            .site-nav__account {
              @include container;

              .change_market {
                color: $dark-grey;
                display: flex !important;
                padding: 2rem 0 2rem 1rem;

                span {
                  color: $dark-grey;
                  padding-right: 10px;
                }

                .nav__menu__link {
                  color: $dark-grey;
                }
              }
            }
          }
        }
      }

      .nav__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        padding-left: 12px;

        @media (min-width: $md) {
          width: 110px;
          padding-left: 0;
          padding-right: 30px;
        }

        a {
          transition: 0.3s;
          opacity: 1;

          &:hover {
            opacity: 0.7;
          }

          img {
            width: 140px;
            height: 100%;
            max-width: unset;
            padding: 27px 0;
            transition: 0.3s;

            @media (min-width: $md) {
              width: 270px;
            }

            &.scrolled {
              @media (min-width: $md) {
                width: 140px;
              }
            }
          }
        }
      }

      .nav__account {
        min-height: 50px;
        height: 100%;
        margin-right: 0;

        .change_market {
          order: 0;
          font-size: 15px;
          display: none !important;

          @media (min-width: 992px) {
            display: flex !important;
          }

          &--title {
            width: 100px;
          }

          span {
            color: $dark-grey;
          }

          .nav__menu__link {
            color: $dark-grey;
            width: 128px;

            &::after {
              color: $dark-grey;
            }
          }
        }

        .auth {
          &__nav {
            order: 1;
          }

          &__link {
            display: flex;
            align-items: center;
          }
        }

        .cart {
          &__nav {
            order: 2;
          }

          &__link {
            display: flex;
            align-items: center;
          }

          &__number {
            font-family: "Karla", sans-serif;
            font-size: 12px;
            line-height: 14px;
            position: absolute;
            top: -5px;
            right: -5px;
            color: $dark-grey;
            background-color: transparent;
          }
        }
      }

      .locale-switcher {
        order: 3;
        padding-left: 16px;

        @media (min-width: 992px) {
          padding-left: 0;
        }
      }
    }
  }
}

// Search
.nav__account__search_icon__left {
  @media(max-width: 767px) {
    order: 4;
    padding-left: 16px;
    display: flex;
    align-items: center;

    &::before {
      content: 'Search';
      display: inline-block;
      padding-right: 16px;
    }
  }
}