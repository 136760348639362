.text-content.award-banner {
  background-color: $white;

  .text-content__title {
    @include subtitle;
  }

  .text-content__text {
    .container {
      .row {
        .col-12 {
          padding: 14px 0;

          &.col-3 {
            .award-name {
              font-family: "Karla", sans-serif;
              color: $gold;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 2.4px;
            }
          }

          &.col-9 {
            .award-value {
              font-family: "Crimson Pro", serif;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
