@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;1,400;1,500&family=Karla:wght@200;300;400;500;700&family=Open+Sans:wght@600&display=swap");

// Color Palette
$white: #ffffff;
$grey-light: #f6f4f2;
$grey-lighter: #ede7e1;
$gold: #bd9b60;
$grey: #989898;
$red: #a45248;
$red-christmas: #AE2F36;
$brown-grey: #b0a198;
$dark-brown-grey: #82756f;
$dark-grey: #53565a;
$archive-box-grey: #edeae7;
$blue: #041e42;
$black: #000000;
$trans-dark: rgba(4, 30, 66, 0.7);
$trans-white: rgba(255, 255, 255, 0.7);

$pink: #f8c8d8;
$pink-dark: #e50c7c;

// Breakpoint
$micro: 350px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$giga: 2000px;
$xgiga: 3500px;
