.banner {
  &__section {
    &__pettegola,
    &__pettegola__bundle,
    &__video {
      padding: 78px 0 85px;

      .container {
        height: 100%;
      }
    }

    &__pettegola {
      background-color: $grey-lighter;

      .banner__content {
        .subtitle {
          color: $dark-grey;
          max-width: 600px;
        }

        .text {
          color: $brown-grey;
          max-width: 320px;
        }
      }

      &__bundle {
        background-color: $grey-lighter;

        .banner__content {
          .subtitle {
            color: $dark-grey;
            max-width: 500px;
          }

          .text {
            color: $brown-grey;
            // max-width: 320px;
          }
        }
      }
    }

    &__video {
      background-color: $grey-light;

      &.bg-white {
        background-color: $white;
      }

      .banner__content {
        justify-content: flex-start;

        .subtitle {
          color: $brown-grey;
        }

        .text {
          color: $grey;
          max-width: 420px;
        }
      }
    }

    &__image {
      p {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .subtitle {
      margin-bottom: 32px;
    }

    .text {
      margin-bottom: 32px;
    }

    .btn {
      margin-bottom: 32px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding-bottom: 32px;

    @media (min-width: 768px) {
      max-width: 475px;
      padding-bottom: 0;
    }
  }

  &__video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
