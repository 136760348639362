.related-product {
  .related-product__title {
    @include container;
    @include intro;
    text-align: left;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 3px;
    color: $gold;
    font-weight: 400;
  }

  .related-product__content {
    @include container;

    .product-list {
      @include row;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      margin-right: 0;
      margin-left: 0;

      .product-list__item {
        @include col-12;
        @include col-md-6;
        @include col-lg;
        min-height: 100%;
        margin: 0;

        .product-box {
          height: 100%;
          background-color: transparent;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          padding-bottom: 23px;
          gap: 12px;

          .product-box__header {
            padding: 0;

            a {
              .product-box__title {
                font-family: "Crimson Pro", serif;
                font-weight: 400;
                font-size: 18px;
                color: $dark-brown-grey;
                line-height: 25px;
                margin: 0;
                padding-bottom: 12px;
                max-height: unset;
                min-height: unset;
              }

              .product-box__image {
                img {
                  padding: 0;
                  padding-bottom: 34px;
                  aspect-ratio: 1;
                  min-width: 100%;
                }
              }
            }
          }

          .product-box__body {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 84px;

            .product-box__price {
              @include text;
              font-weight: 700;
              margin-bottom: 24px;
            }

            .product-box__action {
              margin: 0;
              display: flex;
              justify-content: center;

              .button--primary {
                @include btn;
                width: 187px;
                height: 41px;
                letter-spacing: 0;
              }
            }

            .product-box__notavailable {
              @include text;
              font-weight: 700;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
