.slideshow {
  margin-top: 0;
  margin-bottom: 0;

  &.home-mobile-slider-section {
    @media (min-width: $md) {
      display: none;
    }

    .swiper {
      &-slide[data-swiper-slide-index="0"] {
        .slideshow-slide__image {
          img {
            object-position: center;
          }
        }
      }
    }
  }

  &.home-slider-section {
    .swiper {
      display: none;

      @media (min-width: $md) {
        display: block;
      }
    }
  }

  .swiper {
    &-button {
      &-next,
      &-prev {
        display: none;
      }
    }

    &-wrapper {
      @media (min-width: $lg) {
        height: unset;
        max-height: 760px;
        aspect-ratio: 2.2;
      }
    }

    &-pagination {
      bottom: 20px;

      @media (min-width: $md) {
        bottom: 66px;
      }

      .container {
        display: flex;
        justify-content: start;
        align-items: start;

        @media (min-width: $md) {
          justify-content: flex-start;
          align-items: center;
        }
      }

      &-bullet {
        width: 20px;
        height: 2px;
        border: none;
        border-radius: 0;
        background-color: $white;
        opacity: 0.5;
        transition: 0.3s;

        &-active {
          opacity: 1;
          background-color: $white !important;
        }
      }
    }
  }

  &__link {
    &.christmas-slide {
      .slide {
        &-title {
          color: $gold;
        }

        &-text {
          color: $gold;
        }

        &-btn {
          color: $gold !important;
          background-color: $red-christmas !important;

          &:hover {
            color: $red-christmas !important;
            background-color: $gold !important;
          }
        }
      }
    }
  }

  &__title {
    display: none;
  }

  &-slide {
    &__title,
    &__text {
      display: none;
    }

    &__image {
      position: absolute;
      img {
        object-position: right center;

        @media (min-width: $md) {
          object-position: center;
        }
      }
    }
  }

  .slide {
    &-wrapper {
      @include container;
      color: $white;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 100px;

      @media (min-width: $md) {
        justify-content: center;
        padding-top: 0;
      }
    }

    &-title {
      @include title;
      color: $white;
      font-size: 27px;
      line-height: 35px;
      margin-bottom: 20px;
      text-align: left;
      // max-width: 250px;

      @media (min-width: $sm) {
        font-size: 60px;
        line-height: 65px;
      }

      @media (min-width: $md) {
        font-size: 30px;
        line-height: 35px;
        max-width: 630px;
      }

      @media (min-width: $lg) {
        font-size: 60px;
        line-height: 65px;
      }
    }

    &-text {
      @include text;
      font-size: 22px;
      line-height: 24px;
      text-align: left;
      max-width: 250px;

      @media (min-width: $md) {
        max-width: unset;
      }

      @media (min-width: $xl) {
        margin-bottom: 100px;
      }
    }

    &-btn {
      @include btn;
      width: 100px;

      @media (min-width: $md) {
        width: 200px;
      }
    }
  }
}

// Slide Pettegola
.pettegola-slide {
  .slide {
    &-wrapper {
      padding-top: 50px;
      @media (min-width: 768px) {
        padding-top: 100px;
      }
    }

    &-title {
      max-width: 309px;

      @media (min-width: 768px) {
        max-width: 350px !important;
      }
      @media (min-width: 992px) {
        max-width: 700px !important;
      }
    }

    &-text {
      @media (min-width: 768px) {
        max-width: 470px !important;
      }
    }
  }

  &-mobile {
    .slide {
      &-wrapper {
        padding-top: 50px;
      }

      &-title {
        max-width: 60% !important;

        @media (min-width: 576px) {
          font-size: 45px;
          line-height: 45px;
        }
      }
    }
  }
}
