@mixin container {
  width: 100%;
  padding-right: calc(1.5rem * 0.5);
  padding-left: calc(1.5rem * 0.5);
  margin-right: auto !important;
  margin-left: auto !important;

  @media (min-width: $sm) {
    max-width: 540px;
  }

  @media (min-width: $md) {
    max-width: 720px;
  }

  @media (min-width: $lg) {
    max-width: 960px;
  }

  @media (min-width: $xl) {
    max-width: 1140px;
  }

  @media (min-width: $xxl) {
    max-width: 1320px;
  }

  @media (min-width: $giga) {
    max-width: 1800px !important;
  }

  @media (min-width: $xgiga) {
    max-width: 3000px !important;
  }
}

@mixin container-xxl {
  width: 100%;
  padding-right: calc(1.5rem * 0.5);
  padding-left: calc(1.5rem * 0.5);
  margin-right: auto !important;
  margin-left: auto !important;

  @media (min-width: $xxl) {
    max-width: 1320px !important;
  }

  @media (min-width: $giga) {
    max-width: 1800px !important;
  }

  @media (min-width: $xgiga) {
    max-width: 3000px !important;
  }
}

@mixin row {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * 0);
  margin-right: calc(-0.5 * 1.5rem);
  margin-left: calc(-0.5 * 1.5rem);

  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(1.5rem * 0.5);
    padding-left: calc(1.5rem * 0.5);
    margin-top: 0;
  }
}

@mixin col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@mixin col-md-6 {
  @media (min-width: $md) {
    flex: 0 0 auto;
    width: 50%;
  }
}

@mixin col-lg {
  @media (min-width: $lg) {
    flex: 1 0 0%;
  }
}

@mixin transition_custom {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

@mixin title {
  font-family: "Crimson Pro", serif;
  font-size: 60px;
  line-height: 65px;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 50px;
}

@mixin intro {
  font-family: "Crimson Pro", serif;
  font-size: 16px;
  line-height: 17px;
  font-weight: 400;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 0;
}

@mixin subtitle {
  font-family: "Crimson Pro", serif;
  font-weight: 300;
  letter-spacing: 0;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 20px;

  @media (min-width: $md) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 40px;
  }
}

@mixin text {
  font-family: "Karla", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 40px;
}

@mixin btn($txt-color: $white, $bg-color: $pink-dark, $border-color: $pink-dark) {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: unset;
  max-width: 200px;
  width: 100%;
  height: 38px;
  padding: 0 !important;
  font-family: "Karla", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: $txt-color !important;
  background-color: $bg-color !important;
  border: 1px solid $border-color !important;
  transition: all 0.3s ease-in-out;
  filter: brightness(100%);

  &:hover {
    filter: brightness(110%);
    color: $bg-color !important;
    background-color: $txt-color !important;
  }

  @media (min-width: $md) {
    width: 242px;
    height: 49px;
  }
}

@mixin newsletter-link {
  font-family: "Karla", sans-serif;
  font-size: 0.9rem;
  line-height: 1.06rem;
  letter-spacing: 2.1px;
  color: $black;
  text-decoration: underline;
  text-transform: uppercase;
}

@mixin single-prod-title {
  font-family: "Karla", sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2.4px;
}
